<template>
  <v-app>
    <v-main>
      <notification v-if="getNotification.show" />
      <router-view />
      <cookieDialogue />
    </v-main>
  </v-app>
</template>

<script>
import "@/assets/styles/app.css";
import "@/assets/styles/pagination.css";
import cookieDialogue from "./components/cookieDialogue.vue";
import notification from "./components/notification.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    cookieDialogue,
    notification,
  },
  computed: {
    ...mapGetters(["getNotification"]),
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss"></style>
