<template>
  <div class="record-list-container">
    <div class="row justify-content-center">
      <div
        class="col-4 record-list-item"
        v-for="(courses, i) in records"
        :key="i"
      >
        <div class="articles-list-wrapper">
          <div
            v-for="(courseItems, x) in courses.fields.listItem.content"
            :key="x"
            :style="
              courseItems?.nodeType === 'paragraph' && x < 5
                ? { marginBottom: 'auto' }
                : {}
            "
          >
            <div v-if="courseItems?.nodeType === 'embedded-asset-block'">
              <img class="record-list-image" :src="images[i]" />
            </div>
            <div
              v-if="courseItems?.nodeType === 'heading-6' && x < 3"
              class="record-list-subtext"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              v-if="
                courseItems?.nodeType === 'heading-3' &&
                !courseItems?.content[0]?.marks?.length
              "
              class="record-list-title"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              v-if="courseItems?.nodeType === 'paragraph'"
              class="record-list-paragraph"
            >
              {{ courseItems.content[0].value }}
            </div>
            <div
              class="record-list-left"
              v-if="courseItems?.nodeType?.includes('heading') && x > 3"
            >
              <div
                class="record-list-author"
                v-if="courseItems?.nodeType === 'heading-6'"
              >
                {{ courseItems.content[0].value }}
              </div>
              <div
                class="record-list-date"
                v-if="courseItems?.nodeType === 'heading-5'"
              >
                {{ courseItems.content[0].value }}
              </div>
            </div>
            <div
              class="record-list-right"
              v-if="
                courseItems?.nodeType === 'heading-3' &&
                courseItems?.content[0]?.marks[0]?.type === 'underline'
              "
            >
              <md-filled-tonal-button
                class="record-list-read-more-button"
                @click="$router.push(`/article/${courses?.sys?.id}`)"
              >
                {{ courseItems.content[0].value }}
                <i class="mdi mdi-arrow-right"></i>
              </md-filled-tonal-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["records"],
  name: "recordList",
  data() {
    return {
      images: [],
    };
  },
  watch: {
    records() {
      this.fetchImages();
    },
  },
  mounted() {
    if (this.records.length > 0) {
      this.fetchImages();
    }
  },
  methods: {
    fetchImages() {
      this.records.forEach((courses, i) => {
        this.getImageURL(
          courses.fields.listItem.content[0].data.target.sys.id,
          i
        );
      });
    },
    getImageURL(assetId, x) {
      if (assetId) {
        axios
          .get(
            `https://cdn.contentful.com/spaces/8nqnktj51aoq/assets/${assetId}?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k`
          )
          .then((response) => {
            this.images[x] = `https:${response.data.fields.file.url}`;
          });
      }
    },
  },
};
</script>

<style></style>
