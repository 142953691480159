<template>
  <div class="footer-container">
    <div class="row">
      <div class="col-3 footer-cols">
        <img
          class="footer-logo"
          :src="require('@/assets/images/logoColoredWhite.png')"
          alt=""
        />
        <div class="footer-col-1-text">CONNECT</div>
        <div class="footer-col-1-text">LEARN</div>
        <div class="footer-col-1-text">GROW</div>
        <div class="footer-col-1-button">
          <md-filled-tonal-button class="record-list-read-more-button">
            Get Started
            <i class="mdi mdi-arrow-right"></i>
          </md-filled-tonal-button>
        </div>
      </div>
      <div class="col-2 footer-cols">
        <div class="footer-cols-title">Courses</div>
        <div class="footer-cols-links" @click="$router.push('/courses')">
          For Adults
        </div>
        <div class="footer-cols-links" @click="$router.push('/courses')">
          For Teenagers
        </div>
        <div class="footer-cols-links" @click="$router.push('/courses')">
          For Kids
        </div>
      </div>
      <div class="col-2 footer-cols">
        <div class="footer-cols-title">Solutions</div>
        <div class="footer-cols-links" @click="$router.push('/K12-schools')">
          For K-12 education
        </div>
        <div class="footer-cols-links" @click="$router.push('/IB-schools')">
          For IB schools
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/higher-education')"
        >
          For higher education
        </div>
        <div class="footer-cols-links" @click="$router.push('/private-sector')">
          For private sector
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/public-and-non-profits')"
        >
          For public sector and non-profits
        </div>
        <div class="footer-cols-links" @click="$router.push('/corporates')">
          For corporates
        </div>
      </div>
      <div class="col-2 footer-cols">
        <div class="footer-cols-title" @click="$router.push('/about-us')">
          About us
        </div>
        <div class="footer-cols-links" @click="$router.push('/about-us')">
          Our Academic team
        </div>
        <div class="footer-cols-links" @click="$router.push('/about-us')">
          Our teachers
        </div>
      </div>
      <div class="col-2 footer-cols">
        <div class="footer-cols-title">Resources</div>
        <div class="footer-cols-links" @click="$router.push('/articles')">
          Articles
        </div>
        <div class="footer-cols-links" @click="$router.push('/resources')">
          Guides
        </div>
        <div class="footer-cols-links" @click="$router.push('/eBooks')">
          Ebooks
        </div>
        <div class="footer-cols-links" @click="$router.push('/events')">
          Events
        </div>
      </div>
      <div class="col-1 footer-cols">
        <div class="footer-cols-title">Contact us</div>
        <div class="footer-cols-links" @click="$router.push('/get-in-touch')">
          Contact
        </div>
        <div class="footer-cols-links" @click="$router.push('/faqs')">FAQs</div>
      </div>
    </div>
    <div class="footer-bottom-links">
      <div
        class="footer-cols-links"
        @click="$router.push('/terms-and-conditions')"
      >
        Terms and Conditions
      </div>
      <div class="footer-cols-links">Privacy Policy</div>
      <div class="footer-cols-links">Cancellation Policy</div>
      <div class="footer-cols-links">Accessibility Policy</div>
      <div class="footer-cols-links">Non-discrimination Policy</div>
      <div class="footer-cols-links">Cookies Policy</div>
      <div
        class="footer-cols-links"
        @click="$router.push('/terms-and-conditions')"
      >
        Cookies Consent Manager
      </div>
    </div>
    <div class="footer-copyright">
      <div class="footer-cols-links mr-auto">
        © 2024 Comligo. All rights reserved.
      </div>
      <div class="footer-icons-group">
        <div class="footer-icons-button">
          <i class="mdi mdi-facebook footer-icons"></i>
        </div>
        <div class="footer-icons-button">
          <i class="mdi mdi-linkedin footer-icons"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style></style>
