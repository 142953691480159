<template>
  <div
    class="header-container"
    :style="{
      'background-image': `url(${data.bg})`,
    }"
  >
    <headerMenu />
    <div class="header-cta-section">
      <div class="header-cta-wrapper">
        <div class="row">
          <div class="row">
            <div class="col-lg-8 header-section-left">
              <div class="header-title">
                <h1>{{ data.title1 }}</h1>
                <h1>{{ data.title2 }}</h1>
              </div>
              <div class="header-subtitle">
                {{ data.desc1 }}
              </div>
              <div class="header-subtitle">
                {{ data.desc2 }}
              </div>
              <div class="header-subtitle">{{ data.desc3 }}</div>
              <div class="header-buttons">
                <md-filled-tonal-button
                  class="get-started-button"
                  @click="$router.push('/book-a-demo')"
                >
                  {{ data.button1 }}
                  <i class="mdi mdi-arrow-right"></i>
                </md-filled-tonal-button>
                <md-outlined-button
                  class="discover-more-button"
                  @click="goToDiv(data.scrollElement)"
                  >{{ data.button2 }}</md-outlined-button
                >
              </div>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerMenu from "@/components/headerMenu.vue";

export default {
  props: ["data"],
  components: {
    headerMenu,
  },
  methods: {
    goToDiv(id) {
      document.querySelector(id).scrollIntoView();
    },
  },
};
</script>

<style></style>
