export default {
  getHomepageCourses: (state) => state.homepageCourses,
  getCourseList: (state) => state.courses,
  getCourse: (state) => state.course,
  getTrustedCompanies: (state) => state.trustedCompanies,
  getTestimonials: (state) => state.testimonials,
  getEmpoweringLanguage: (state) => state.empoweringLanguage,
  gethomeCaseStudies: (state) => state.homeCaseStudies,
  getHomepageInsights: (state) => state.homepageInsights,
  getHomePageHeader: (state) => state.homePageHeader,
  getCoursesHeader: (state) => state.coursesHeader,
  getUnderstandingCourses: (state) => state.understandingCourses,
  getIncludedInOurCourses: (state) => state.includedInOurCourses,
  getComprehensiveCourses: (state) => state.comprehensiveCourses,
  getBreadcrumbs: (state) => state.breadcrumbs,
  getArticles: (state) => state.articles,
  getArticle: (state) => state.article,
  getK12Header: (state) => state.K12Header,
  getOurSolutionsHome: (state) => state.ourSolutionsHome,
  getOurSolutionsK12: (state) => state.ourSolutionsK12,
  getOurSolutionsIB: (state) => state.ourSolutionsIB,
  getOurSolutionsHigherEd: (state) => state.ourSolutionsHigherEd,
  getOurSolutionsPublic: (state) => state.ourSolutionsPublic,
  getOurSolutionsPrivate: (state) => state.ourSolutionsPrivate,
  getOurSolutionsCorporate: (state) => state.ourSolutionsCorporate,
  getYourChallengesK12: (state) => state.yourChallengesK12,
  getYourChallengesIB: (state) => state.yourChallengesIB,
  getYourChallengesHigherEducation: (state) =>
    state.yourChallengesHigherEducation,
  getYourChallengesPublic: (state) => state.yourChallengesPublic,
  getYourChallengesPrivate: (state) => state.yourChallengesPrivate,
  getYourChallengesCorporate: (state) => state.yourChallengesCorporate,
  getCollaborationsTimelineK12: (state) => state.collaborationsTimelineK12,
  getCollaborationsTimelineIB: (state) => state.collaborationsTimelineIB,
  getCollaborationsTimelineHigherEducation: (state) =>
    state.collaborationsTimelineHigherEducation,
  getCollaborationsTimelinePublic: (state) =>
    state.collaborationsTimelinePublic,
  getCollaborationsTimelinePrivate: (state) =>
    state.collaborationsTimelinePrivate,
  getCollaborationsTimelineCorporate: (state) =>
    state.collaborationsTimelineCorporate,
  geteBooks: (state) => state.eBooks,
  geteBook: (state) => state.eBook,
  getCaseStudies: (state) => state.caseStudies,
  getCaseStudy: (state) => state.caseStudy,
  getK12Providers: (state) => state.k12Providers,
  getK12Provider: (state) => state.k12Provider,
  getK12ProviderHeader: (state) => state.K12ProviderHeader,
  getCookiePolicy: (state) => state.cookiePolicy,
  getIBHeader: (state) => state.IBHeader,
  getHigherEducationHeader: (state) => state.higherEducationHeader,
  getPrivateSectorHeader: (state) => state.privateSectorHeader,
  getCorporateSectorHeader: (state) => state.corporateSectorHeader,
  getNonProfitsHeader: (state) => state.nonProfitsHeader,
  getPricingPlans: (state) => state.pricingPlans,
  getfrequentlyAskedQuestions: (state) => state.frequentlyAskedQuestions,
  getAboutUsHeader: (state) => state.AboutUsHeader,
  getMissionsAndVisions: (state) => state.missionsAndVisions,
  getMeetOurTeam: (state) => state.meetOurTeam,
  getResourcesHeader: (state) => state.resourcesHeader,
  getGuides: (state) => state.guides,
  getGuide: (state) => state.guide,
  getEvents: (state) => state.events,
  getEvent: (state) => state.event,
  getConsent: (state) => state.consent,
  getNotification: (state) => state.notification,
  getDialog: (state) => state.dialog,
};
