<template>
  <div class="success-stories-container">
    <div class="success-stories-title">Success stories</div>
    <div class="row success-stories-swiper">
      <div class="col-1 success-stories-buttons">
        <div class="partner-statements-navigation-buttons swiper-button-prev">
          <i class="mdi mdi-arrow-left"></i>
        </div>
      </div>
      <div class="col-10">
        <swiper
          ref="swiperRef"
          :slides-per-view="1"
          :space-between="50"
          :loop="true"
          :pagination="{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }"
          :speed="500"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
        >
          <swiper-slide v-for="(slide, i) in getCaseStudies" :key="i">
            <div class="success-stories-wrapper">
              <div class="success-stories-sections-left">
                <img
                  :src="gethomeCaseStudies[i]?.image"
                  class="success-stories-slide-image"
                />
              </div>
              <div class="success-stories-sections-right">
                <div
                  v-for="(item, x) in slide?.fields?.listItem?.content"
                  :key="x"
                >
                  <div v-if="x === 0" class="success-stories-slide-title">
                    {{ item?.content[0]?.value }}
                  </div>
                  <div v-if="x === 1" class="success-stories-slide-description">
                    {{ item?.content[0]?.value }}
                  </div>
                  <div
                    v-if="
                      [2, 3].includes(x) &&
                      (item?.content[0]?.value.toLowerCase().includes('read') ||
                        item?.content[1]?.value.toLowerCase().includes('read'))
                    "
                    class="success-stories-slide-title"
                  >
                    <md-filled-tonal-button
                      class="success-stories-slide-button"
                      @click="$router.push(`/case-study/${slide?.sys?.id}`)"
                    >
                      {{ item?.content[1]?.value }}{{ item?.content[0]?.value }}
                      <i class="mdi mdi-arrow-right"></i>
                    </md-filled-tonal-button>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" />
        </swiper>
      </div>
      <div class="col-1 success-stories-buttons">
        <div class="partner-statements-navigation-buttons swiper-button-next">
          <i class="mdi mdi-arrow-right"></i>
        </div>
      </div>
    </div>
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/case-studies')"
    >
      Read our case studies
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { mapGetters } from "vuex";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "SuccessStories",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(["gethomeCaseStudies", "getCaseStudies"]),
  },
};
</script>

<style></style>
