<template>
  <div class="trusted-companies-swiper">
    <div class="trusted-companies-swiper-overlay" :style="{ background }"></div>
    <div class="trusted-companies-swiper-title">Trusted by</div>
    <div class="trusted-companies-swiper-wrapper">
      <swiper
        :slides-per-view="6"
        :space-between="50"
        :loop="true"
        :freeMode="true"
        :autoplay="{
          delay: 0,
          disableOnInteraction: false,
        }"
        :speed="6000"
      >
        <swiper-slide
          class="trusted-companies-logos-wrapper"
          v-for="(slide, i) in getTrustedCompanies"
          :key="i"
        >
          <img :src="slide" class="trusted-companies-logos" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { mapGetters } from "vuex";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "TrustedComapnies",
  props: ["background"],
  computed: {
    ...mapGetters(["getTrustedCompanies"]),
  },
  data() {
    return {};
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style></style>
