<template>
  <div class="our-solutions-container">
    <div class="row">
      <div class="col-6 our-solutions-list">
        <div class="our-solutions-badge">{{ solutions.tag }}</div>
        <div class="our-solutions-title">{{ solutions.title1 }}</div>
        <div
          class="our-solutions-list-item"
          v-for="(solution, x) in solutions.solutions"
          :key="x"
        >
          <i class="mdi mdi-check-circle our-solutions-check"></i>
          <div>
            <b>{{ solution.bold }}</b> {{ solution.text }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="our-solutions-right">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/connectBG.png?alt=media&token=4c362ab1-b77e-47b9-bad3-8101be624582"
            class="our-solutions-image"
            alt=""
          />
          <div class="our-solutions-slogan">
            <div>{{ solutions.slogan1 }}</div>
            <div>{{ solutions.slogan2 }}</div>
            <div>{{ solutions.slogan3 }}</div>
          </div>
        </div>
      </div>
    </div>
    <md-filled-tonal-button
      class="get-started-button our-solutions-button"
      v-if="solutions?.button"
      @click="$router.push('/K12-programs')"
    >
      {{ solutions?.button }}
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
export default {
  name: "OurSolutions",
  props: ["solutions"],
};
</script>

<style></style>
