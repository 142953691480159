<template>
  <div class="latest-insights-container">
    <div class="latest-insights-badge">From our articles</div>
    <div class="latest-insights-title">Get into our latest insights</div>
    <recordListComponent
      class="our-courses-record-list"
      :records="getArticles"
    />
    <md-filled-tonal-button class="get-started-button">
      Read more articles
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
import recordListComponent from "./recordListComponentArticles.vue";
import { mapGetters } from "vuex";

export default {
  name: "OurCoursesComponent",
  components: {
    recordListComponent,
  },
  computed: {
    ...mapGetters(["getArticles"]),
  },
};
</script>

<style></style>
