import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/courses",
    name: "courses",
    component: () => import("@/views/Courses.vue"),
  },
  {
    path: "/course/:id",
    name: "course",
    component: () => import("@/views/Course.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404Component.vue"),
  },
  {
    path: "/articles",
    name: "articles",
    component: () => import("@/views/Articles.vue"),
  },
  {
    path: "/article/:id",
    name: "article",
    component: () => import("@/views/Article.vue"),
  },
  {
    path: "/eBooks",
    name: "ebooks",
    component: () => import("@/views/EBooks.vue"),
  },
  {
    path: "/eBook/:id",
    name: "ebook",
    component: () => import("@/views/eBook.vue"),
  },
  {
    path: "/case-studies",
    name: "case studies",
    component: () => import("@/views/CaseStudies.vue"),
  },
  {
    path: "/case-study/:id",
    name: "case study",
    component: () => import("@/views/CaseStudy.vue"),
  },
  {
    path: "/K12-schools",
    name: "k12",
    component: () => import("@/views/K12Component.vue"),
  },
  {
    path: "/IB-schools",
    name: "IB schools",
    component: () => import("@/views/IBschools.vue"),
  },
  {
    path: "/K12-program/:id",
    name: "k12 provider",
    component: () => import("@/views/K12Provider.vue"),
  },
  {
    path: "/K12-programs",
    name: "k12 providers",
    component: () => import("@/views/K12Providers.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms and conditions",
    component: () => import("@/views/cookiesPolicy.vue"),
  },
  {
    path: "/public-and-non-profits",
    name: "public and non profits",
    component: () => import("@/views/nonProfits.vue"),
  },
  {
    path: "/private-sector",
    name: "private sector",
    component: () => import("@/views/privateSector.vue"),
  },
  {
    path: "/corporates",
    name: "corporates",
    component: () => import("@/views/corporates.vue"),
  },
  {
    path: "/higher-education",
    name: "higher education",
    component: () => import("@/views/higherEducation.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/PricingComponent.vue"),
  },
  {
    path: "/about-us",
    name: "about us",
    component: () => import("@/views/AboutUs.vue"),
  },
  {
    path: "/get-in-touch",
    name: "get in touch",
    component: () => import("@/views/getInTouch.vue"),
  },
  {
    path: "/resources",
    name: "resources",
    component: () => import("@/views/resourcesComponent.vue"),
  },
  {
    path: "/resource/:id",
    name: "resource",
    component: () => import("@/views/resourcePage.vue"),
  },
  {
    path: "/events",
    name: "events",
    component: () => import("@/views/events.vue"),
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import("@/views/eventPage.vue"),
  },
  {
    path: "/book-a-demo",
    name: "Book a free demo",
    component: () => import("@/views/bookADemo.vue"),
  },
  {
    path: "/personal-information",
    name: "Personal information",
    component: () => import("@/views/personalInformation.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("@/views/frequentlyAskedQuestions.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
